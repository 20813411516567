import angular from 'angular';
import uirouter from 'angular-ui-router';
import config from 'config';
import tooltips from 'angular-tooltips';
import loading from 'angular-loading-bar';
import translate from 'angular-translate';
import translateLoaderStaticFiles from 'angular-translate-loader-static-files';
import 'angular-socialshare';
// Import our modules.
import index from 'index/index.module';
import create from 'create/create.module';
import finish from 'finish/finish.module';
import edit from 'edit/edit.module';

// Import styles.
import 'angular-tooltips/dist/angular-tooltips.min.css';
import 'angular-loading-bar/build/loading-bar.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'sweetalert/dist/sweetalert.css';
import 'main';

// App init.
// @ngInject
const init = function($rootScope, $state) {
  // Inject $state into $rootScope.
  $rootScope.state = $state;
};

// Create app.
export default angular.module('taplauseWizard', [uirouter, tooltips, loading, translate, translateLoaderStaticFiles, '720kb.socialshare', index, create, finish, edit])
//export default angular.module('taplauseWizard', [uirouter, tooltips, loading, translate, index, create, finish, edit])
  .config(config)
  .config(['$translateProvider', function($translateProvider) {
    $translateProvider.useStaticFilesLoader({
      prefix: '/resources/locale-',
      suffix: '.json'
    })
    .registerAvailableLanguageKeys(['en', 'fi'], {
      'en_*': 'en',
      'fi_*': 'fi',
      '*': 'en'
    })
    .useSanitizeValueStrategy('sanitizeParameters')
    .fallbackLanguage('en')
    .determinePreferredLanguage();
  }])
  .run(init)
  .name;
