import { isEmail } from 'validator';
import { copy } from 'angular';
import { QUESTIONS, PICTURES, IS_VALID_TARGET } from 'target';
import { IS_VALID_DASHBOARD } from 'dashboard';

export default class CreateController {
  // @ngInject
  constructor($state, HTTPService, $q, $translate) {
    this.currentLanguage = $translate.use() || 'en';
    this.state = $state;
    this.http = HTTPService;
    this.q = $q;
    this.requestPending = false;

    this.name = '';
    this.email = '';
    this.questions = QUESTIONS;
    this.pictures = PICTURES;

    this.currentQuestion = this.questions[0];
    this.currentPicture = this.pictures[0];

    this.isEmail = isEmail;
  }

  /**
   * Set selected question.
   * @param {Number} idx Question index.
   */
  setQuestion(idx) {
    if (this.questions[idx]) {
      this.currentQuestion = this.questions[idx];
    }
  }

  /**
   * Set selected picture.
   * @param {Number} idx Picture index.
   */
  setPicture(idx) {
    if (this.pictures[idx]) {
      this.currentPicture = this.pictures[idx];
    }
  }

  /**
   * Send request to create new dashboard.
   */
  createDashboard(params) {
    // Re-validate email and name.
    if (!this.isEmail(this.email) || this.name.length === 0) {
      return false;
    }

    return this.http.createDashboard(params);
  }

  /**
   * Send request to create new target. TODO: naming createTargetAndDashboard
   */
  createTarget() {
    // console.info('Re-validate email and name.');
    if (!this.isEmail(this.email) || this.name.length === 0) {
      return false;
    }

    // console.info('Request is pending - disable button to prevent extra clicks.');
    this.requestPending = true;

    // Get language.
    let lang = window.navigator.userLanguage || window.navigator.language || 'en';
    lang = lang.substring(0, 2);

    let target = {
      name: this.name,
      question: copy(this.currentQuestion),
      picture: this.currentPicture,
      customizations: {
        question: [],
        picture: []
      },
      customerId: 182,
      customerName: 'Wizard',
      sampleFeedback: true,
      language: lang,
      extra: {
        c: { //set plus/minus theme as default
          c5: 'generic/pls',
          c6: 2
        }
      }
    };
    return this.http.createTarget(target)
    .then(response => {
      if (response.status !== 201 || !IS_VALID_TARGET(response.data)) {
        return this.q.reject();
      }
      // Save target id,
      target._id = response.data._id;

      let dashboard = {
        name: this.name,
        email: this.email,
        targets: [response.data._id],
        sendEmail: true,
        rules: {
          disabled: false,
          customer_id: 182,
        }
      };

      return this.createDashboard(dashboard);
    })
    .then(response => {
      if (response.status !== 201 || !IS_VALID_DASHBOARD(response.data)) {
        return this.q.reject();
      }
      //
      this.state.transitionTo('finish', {dashboardId: response.data._id, targetId: target._id});
    }).catch(() => this.requestPending = false);
  }
}
