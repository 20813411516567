/* global swal */
/* eslint-disable no-console */
import { isEmail } from 'validator';
import { QUESTIONS, PICTURES, IS_VALID_TARGET } from 'target';

export default class EditController {
  // @ngInject
  constructor($scope, $state, $stateParams, HTTPService, $q, $timeout) {
    this.scope = $scope;
    this.state = $state;
    this.stateParams = $stateParams;
    this.http = HTTPService;
    this.q = $q;
    this.timeout = $timeout;
    this.requestPending = false;

    this.name = '';
    this.email = '';
    this.questions = QUESTIONS;
    this.pictures = PICTURES;

    this.currentQuestion = this.questions[0];
    this.currentPicture = this.pictures[0];
    this.customQuestion = '';
    this.customizations = {
      question: [],
      picture: []
    };

    // Custom feature settings.
    this.customQuestionMaxLength = 50;
    this.customPictureMaxSize = '1MB';
    this.customPictureTypes = ['.png', '.jpg'];
    this.customPictureTypesPattern = this.customPictureTypes.join(',');

    this.isEmail = isEmail;

    // Get target.
    this.getTarget($stateParams.targetId);
  }

  /**
   * Set selected question.
   * @param {Number} idx Question index.
   */
  setQuestion(idx) {
    if (this.questions[idx]) {
      this.currentQuestion = this.questions[idx];
    }
  }

  /**
   * Set selected picture.
   * @param {Number} idx Picture index.
   */
  setPicture(idx) {
    if (this.pictures[idx]) {
      this.currentPicture = this.pictures[idx];
    }
  }

  /**
   *  Get target data from backend.
   *  @param  {String} id Target ID
   */
  getTarget(id) {
    return this.http.getTarget(id)
      .then(response => {
        if (response.status !== 200 || !IS_VALID_TARGET(response.data)) {
          return this.q.reject();
        }

        this.currentQuestion = response.data.question;
        this.currentPicture = response.data.picture;
        this.customizations = response.data.customizations;

        this.questions = this.questions.concat(this.customizations.question);
        this.pictures = this.pictures.concat(this.customizations.picture);
        this.email = response.data.email;
      })
      .catch(() => {
        this.state.transitionTo('index');
      });
  }

  /**
   * Show confirmation for adding custom features.
   * @param {String} featureType  Type of feature to add.
   */
  unlockFeature(featureType) {
    // Validate either custom question or picture.
    if (featureType === 'question' && this.customQuestion.length == 0) {
      return false;
    } else if (featureType === 'picture' && !this.file) {
      return false;
    }

    // Show sweetalert asking for voucher.
    swal({
      html: true,
      title: 'Are you sure?',
      text: 'If you have a voucher, type it below.<p>To use other payment methods, leave the field empty.</p>',
      type: 'input',
      showCancelButton: true,
      confirmButtonColor: '#00CBFE',
      confirmButtonText: 'Yes, continue',
      closeOnConfirm: false,
      inputPlaceholder: 'Voucher'
    },
    /* istanbul ignore next: untestable */
    (voucher) => {
      if (voucher === false) {
        // User pressed cancel button.
        swal.close();
      } else if (!voucher && typeof voucher === 'string') {
        // User did not use voucher but pressed continue
        this.unlockFeaturePayment(featureType);
      } else {
        // User used voucher and pressed continue
        this.http.validateVoucher(voucher, featureType, this.state.params.targetId)
          // Voucher validate response.
          .then(response => {
            if (response.status !== 200) {
              return this.q.reject();
            }
            // Save validated voucher to be used later.
            this.voucher = voucher;
            this.unlockFeatureSuccess(featureType, true);
          })
          // Errors.
          .catch(() => {
            swal.showInputError('Your voucher is not valid!');
            return false;
          });
      }
    });
  }

  /**
   * Show confirmation for paying custom features with 3rd party services.
   */
  unlockFeaturePayment() {
    /* istanbul ignore next: untestable */
    swal({
      title: 'Not implemented!',
      text: 'Other payment methods are not implemented yet!',
      confirmButtonColor: '#00CBFE'
    });
  }

  /**
   *  Adds custom feature.
   *  @param  {Boolean} confirm If prompt was confirmed.
   */
  unlockFeatureSuccess(featureType, confirm) {
    if (!confirm) {
      return false;
    }

    // Check did we add question or picture.
    if (featureType === 'question') {
      // Confirm-button clicked. Push custom question to question array and target customization array.
      this.questions.push(this.customQuestion.trim());
      this.customizations.question.push(this.customQuestion.trim());

      // Set custom question as the current question and clear the field.
      this.currentQuestion = this.customQuestion;
      this.customQuestion = '';

      // Update target automatically when adding new question.
      this.updateTarget();

      // Mark voucher as used if everything went OK to this point.
      if (this.voucher) {
        this.http.useVoucher(this.voucher, this.state.params.targetId)
          .then(() => {
            this.voucher = null;
          });
      }
    } else {
      let file = this.file;

      // Gather file info.
      let params = {
        filename: file.name,
        filetype: file.type
      };

      // Get Amazon S3 signature for upload.
      // This is not actually necessary for shimmed uploads but it would
      // make this section more complicated to make this conditional.
      return this.http.getS3Signature(params)
        .then(response => {
          if (response.status !== 200) {
            return this.q.reject();
          }

          // After getting signature, upload file using it.
          return this.http.s3Upload(file, response.data, !!file.flashId);
        })
        .then(response => {
          if (response.status !== 200 || !response.data.url) {
            return this.q.reject();
          }

          // Get our image URL.
          this.pictures.push(response.data.url);
          this.customizations.picture.push(response.data.url);

          // Set custom picture as the current picture and clear the field.
          this.currentPicture = response.data.url;
          this.file = null;

          // Update target automatically when adding new picture.
          this.updateTarget();

          // Mark voucher as used if everything went OK to this point.
          if (this.voucher) {
            return this.http.useVoucher(this.voucher, this.state.params.targetId);
          }
        })
        .catch(() => {
          /* istanbul ignore next: untestable */
          swal({
            type: 'error',
            title: 'Oops!',
            text: 'Something went wrong!',
            confirmButtonColor: '#00CBFE',
            closeOnConfirm: false
          });
        })
        .finally(() => {
          // Clear current voucher.
          this.voucher = null;
        });
    }

    /* istanbul ignore next: untestable */
    this.timeout(() => {
      this.scope.$apply();
    });
  }

  /**
   *  Updates target
   *  @param  {Strig} id  Target ID.
   *  @return {Object}    Target update promise.
   */
  updateTarget(id = this.state.params.targetId) {
    // Disable button to prevent extra clicks.
    this.requestPending = true;

    // Target data.
    let target = {
      question: this.currentQuestion,
      picture: this.currentPicture,
      customizations: this.customizations
    };

    // New promise.
    return this.http.updateTarget(id, target)
      .then(response => {
        // Validate response.
        if (response.status !== 200 || !IS_VALID_TARGET(response.data)) {
          return this.q.reject();
        }

        // Show sweetalert success.
        this.requestPending = false;
        swal({
          title: 'Updated!',
          type: 'success',
          showCancelButton: true,
          cancelButtonText: 'OK',
          confirmButtonColor: '#00CBFE',
          confirmButtonText: 'To dashboard'
        },
        /* istanbul ignore next: untestable */
        isConfirm => {
          if (!isConfirm) {
            return false;
          }

          this.updateSuccess(id);
        });
      }).catch(() => this.requestPending = false);
  }

  /**
   *  Transitions to new state.
   *  @param  {String} id Target ID.
   */
  updateSuccess(id) {
    this.state.transitionTo('finish', {dashboardId: this.stateParams.dashboardId, targetId: id});
  }

  /**
   *  Removes current file.
   */
  removeFile() {
    this.file = null;
  }
}
