import angular from 'angular';

/**
 *  Validator for checking that custom questions are unique.
 */
class UniqueQuestion {
  constructor() {
    this.require = 'ngModel';
    this.restrict = 'A';
    this.scope = {
      questions: '=uniqueQuestion'
    };
  }

  link(scope, elem, attrs, ctrl) {
    ctrl.$validators.uniqueQuestion = function() {
      // Check if given question is already in questions.
      return !scope.questions.some(e => ctrl.$viewValue.toLowerCase().trim() === e.toLowerCase().trim());
    };
  }

  static directiveFactory() {
    UniqueQuestion.instance = new UniqueQuestion();
    return UniqueQuestion.instance;
  }
}

export default angular.module('directives.uniqueQuestion', [])
  .directive('uniqueQuestion', UniqueQuestion.directiveFactory)
  .name;
