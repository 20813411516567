import angular from 'angular';

// Styles.
import './index';

import IndexController from './index.ctrl';

export default angular.module('taplauseWizard.index', [])
  .controller('IndexController', IndexController)
  .name;
