import angular from 'angular';
import HTTPService from 'http.service';
import uploader from 'ng-file-upload';
import uniqueQuestion from '../../js/directives/uniqueQuestion.js';

// Styles.
import './edit';

import EditController from './edit.ctrl';

export default angular.module('taplauseWizard.edit', [HTTPService, uploader, uniqueQuestion])
  .controller('EditController', EditController)
  .name;
