export default class FinishController {
  // @ngInject
  constructor($state, $stateParams) {
    this.state = $state;
    this.stateParams = $stateParams;

    this.dashboardUrl = 'https://app.taplause.com/' + $stateParams.dashboardId;
    this.targetUrl = 'https://go.taplause.com/' + $stateParams.targetId;
  }
}
