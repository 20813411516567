/*
import _ from 'lodash';

export const IS_VALID_DASHBOARD = function(dashboard) {
  return _.isObject(dashboard) &&
    _.isString(dashboard._id) &&
    _.isString(dashboard.name) &&
    _.isString(dashboard.email) &&
    _.isArray(dashboard.targets) &&
    dashboard.entityType === 'dashboard';
};
*/
export const IS_VALID_DASHBOARD = function(dashboard) {
  return (typeof dashboard === 'object') &&
  (typeof dashboard._id === 'string') &&
  (typeof dashboard.name === 'string') &&
  (typeof dashboard.email === 'string') &&
  (dashboard.targets.constructor === Array) &&
  dashboard.entityType === 'dashboard';
};
