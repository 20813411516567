import angular from 'angular';
import HTTPService from 'http.service.js';

// Styles.
import './create';

import CreateController from './create.ctrl';

export default angular.module('taplauseWizard.create', [HTTPService])
  .controller('CreateController', CreateController)
  .name;
