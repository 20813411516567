/* eslint-disable no-console */
/* eslint-disable no-console */
import angular from 'angular';
class HTTP {
  // @ngInject
  constructor($http, $q) {
    this.http = $http;
    this.q = $q;

    //this.BASE_URL = 'http://localhost:5007/admin';
    this.BASE_URL = 'https://newapi.taplause.com/admin';
    this.DASHBOARD_BASE_URL = this.BASE_URL + '/dashboard';
    this.TARGET_BASE_URL = this.BASE_URL + '/target';
    this.VOUCHER_BASE_URL = this.BASE_URL + '/voucher';
    this.S3_SIGN_URL = this.BASE_URL + '/sign_s3';
    this.S3_UPLOAD_URL = this.BASE_URL + '/upload_s3';
    // wizard@taplause.com
    this.AUTH0_UID = "auth0|5d5bd83e5d60a70e6ed618bc";
  }

  /**
   *  Send GET request to fetch dashboard
   *  @param  {String} id Dashboard ID
   *  @return {Object}    Promise
   */
  getDashboard(id = null) {
    // Create new promise and deferer.
    let promise = this.http.get(this.DASHBOARD_BASE_URL + '/' + id);
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   *  Send GET request to fetch target
   *  @param  {String} id Target ID
   *  @return {Object}    Promise
   */
  getTarget(id = null) {
    // Create new promise and deferer.
    let promise = this.http.get(this.TARGET_BASE_URL + '/' + id);
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   * Send POST request to backend to create dashboard.
   * @param  {Object} params Dashboard parameters.
   * @return {Object}        HTTP promise.
   */
  createDashboard(params = {}) {
    // Create new promise and deferer.
    params.uid = this.AUTH0_UID; // *** TODO crypto-js
    params.customerId = 182; // Wizard
    let promise = this.http({
      method: 'POST',
      dataType: 'json',
      url: this.DASHBOARD_BASE_URL + '/newW',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        // 'Access-Control-Allow-Origin': '*',
      },
      data: params
    });
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }
  /**
   * Send POST request to backend to create target and assign it to dashboard.
   * @param  {Object} params Target parameters.
   * @return {Object}        HTTP promise.
   */
  createTarget(params = {}) {
    params.uid = this.AUTH0_UID;
    // Create new promise and deferer.
    let promise = this.http({
      method: 'POST',
      dataType: 'json',
      url: this.TARGET_BASE_URL + '/newW',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        // 'Access-Control-Allow-Origin': '*',
      },
      data: params
    });
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   * Send PUT request to backend to update dashboard.
   * @param  {Object} params Dashboard parameters.
   * @return {Object}        HTTP promise.
   */
  updateDashboard(id = null, params = {}) {
    // Create new promise and deferer.
    let promise = this.http.put(this.DASHBOARD_BASE_URL + '/' + id, params);
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   * Send PUT request to backend to update target.
   * @param  {Object} params Target parameters.
   * @return {Object}        HTTP promise.
   */
  updateTarget(id = null, params = {}) {
    // Create new promise and deferer.
    let promise = this.http.put(this.TARGET_BASE_URL + '/' + id, params);
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   *  Validates given voucher.
   *  @param  {String} voucher Voucher code
   *  @return {Object}         Promise.
   */
  validateVoucher(voucher, type, target) {
    let url = target ? `${this.VOUCHER_BASE_URL}/${voucher}/validate/${type}/${target}` : `${this.VOUCHER_BASE_URL}/${voucher}/validate/${type}`;
    // Create new promise and deferer.
    let promise = this.http.get(url);
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   *  Uses given voucher.
   *  @param  {String} voucher Voucher code
   *  @param  {String} target  Target
   *  @return {Object}         Promise.
   */
  useVoucher(voucher, target) {
    let params = {
      owner: target,
      used: true
    };

    // Create new promise and deferer.
    let promise = this.http.put(this.VOUCHER_BASE_URL + '/' + voucher, params);
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   *  Get AWS S3 signature for upload.
   *  @param  {Object} params File information
   *  @return {Object}        HTTP promise.
   */
  getS3Signature(params) {
    // Create new promise and deferer.
    let promise = this.http.post(this.S3_SIGN_URL, params);
    let defer = this.q.defer();

    // Resolve or reject promise.
    promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

    // Return promise.
    return defer.promise;
  }

  /**
   *  Upload file to AWS S3 bucket using either signed url or NodeJS api.
   *  @param  {Object}  file       File
   *  @param  {Object}  params     Signature
   *  @param  {Boolean} fromFlash  Whether file was uploaded from HTML5 compatible browser or from shimmed one.
   *  @return {Object}             HTTP promise
   */
  s3Upload(file, params, fromFlash) {
    // Check if browser supported HTML5 FileAPI.
    if (fromFlash) {
      // Old browser, upload through taplause-api.
      let url = this.S3_UPLOAD_URL;
      let config = {
        // Send base64 decoded file to API.
        body: file.$ngfDataUrl,
        filetype: file.type
      };

      let promise = this.http.put(url, config);
      let defer = this.q.defer();

      // Resolve or reject promise.
      promise.then(response => defer.resolve(response)).catch(error => defer.reject(error));

      // Return promise.
      return defer.promise;
    } else {
      // HTML5 compatible browser, upload using signed request.
      let url = params.signedRequest;
      let config = {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type
        }
      };

      let promise = this.http.put(url, file, config);
      let defer = this.q.defer();

      // Resolve or reject promise.
      promise.then(response => defer.resolve({status: response.status, data: {url: params.url}})).catch(error => defer.reject(error));

      // Return promise.
      return defer.promise;
    }
  }
}

export default angular.module('HTTPService', [])
  .service('HTTPService', HTTP)
  .name;
