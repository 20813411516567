export default class IndexController {
  // @ngInject
  constructor($state, $stateParams, $translate) {
    this.state = $state;
    this.stateParams = $stateParams;
    this.translate = $translate;

    this.initLanguage();
  }

  // Change used locale if it's defined in GET params
  initLanguage() {
    if (this.stateParams.lang && this.translate.getAvailableLanguageKeys().indexOf(this.stateParams.lang) > -1) {
      this.translate.use(this.stateParams.lang);
    }
  }
}
