/**
 * Helper for getting template.
 * @param  {String} featureName Feature name, aka. state.
 * @param  {String} view        Template view name.
 * @return {Object}             Template.
 */
const template = (featureName, view) => require(`../feature/${featureName}/template/${view}.html`);

// Default routes.
// @ngInject
const config = function($urlRouterProvider, $stateProvider, $locationProvider) {
  // Default to /
  $locationProvider.hashPrefix('');
  $urlRouterProvider.otherwise('/');

  // Application wide states.
  $stateProvider
    .state('index', {
      url: '/?lang',
      views: {
        content: {
          template: template('index', 'content'),
          controller: 'IndexController',
          controllerAs: 'index'
        }
      }
    })
    .state('create', {
      abstract: true,
      url: '/create',
      views: {
        content: {
          template: template('create', 'content'),
          controller: 'CreateController',
          controllerAs: 'create'
        }
      }
    })
    .state('create.question', {
      url: '',
      views: {
        tabContent: {
          template: template('create', 'tabContentQuestion')
        }
      }
    })
    .state('create.picture', {
      url: '/picture',
      views: {
        tabContent: {
          template: template('create', 'tabContentPicture')
        }
      }
    })
    .state('create.location', {
      url: '/location',
      views: {
        tabContent: {
          template: template('create', 'tabContentLocation')
        }
      }
    })
    .state('finish', {
      url: '/finish/{dashboardId}/target/{targetId}',
      views: {
        content: {
          template: template('finish', 'content'),
          controller: 'FinishController',
          controllerAs: 'finish'
        }
      }
    });
  // .state('edit', {
  //   abstract: true,
  //   url: '/edit/{dashboardId}/target/{targetId}',
  //   views: {
  //     content: {
  //       template: template('edit', 'content'),
  //       controller: 'EditController',
  //       controllerAs: 'edit'
  //     }
  //   }
  // })
  // .state('edit.question', {
  //   url: '',
  //   views: {
  //     tabContent: {
  //       template: template('edit', 'tabContentQuestion')
  //     }
  //   }
  // })
  // .state('edit.picture', {
  //   url: '/picture',
  //   views: {
  //     tabContent: {
  //       template: template('edit', 'tabContentPicture')
  //     }
  //   }
  // })
  // .state('edit.location', {
  //   url: '/location',
  //   views: {
  //     tabContent: {
  //       template: template('edit', 'tabContentLocation')
  //     }
  //   }
  // });
};

module.exports = config;
