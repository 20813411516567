import angular from 'angular';

// Styles.
import './finish';

import FinishController from './finish.ctrl';

export default angular.module('taplauseWizard.finish', [])
  .controller('FinishController', FinishController)
  .name;
