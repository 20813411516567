// import _ from 'lodash';

export const QUESTIONS = [
  {
    en: [
      'What\'s up?',
      'Great to hear! Tell us more..',
      'Sad to hear :( Want to tell us more?',
      'Add your contact info and you may win an activity tracker!',
      'Thank you!'
    ],
    fi: [
      'Mikä fiilis?',
      'Mahtavaa! Kerro lisää..',
      'Harmi :( Kerrotko lisää?',
      'Jätä yhteystietosi jos haluat osallistua aktiivisuusrannekkeen arvontaan',
      'Kiitos palautteestasi!'
    ]
  },
  {
    en: [
      'How did we serve?',
      'Nice to hear. Please, tell us more...',
      'Sad to hear. Please, tell us more...',
      'Add your contact info and you may win an activity tracker!',
      'Thank you!'
    ],
    fi: [
      'Kuinka palvelimme?',
      'Kiva, mikä erityisesti jäi mieleesi?',
      'Harmi. Mikä meni pieleen?',
      'Jättämallä yhteystietosi osallistut aktiisuusrannekkeen arvontaan',
      'Kiitos palautteestasi!'
    ]
  },
  {
    en: [
      'How was your stay?',
      'Great to hear! Tell us more..',
      'Sad to hear :( Is there some specific reason for this?',
      'Leave your contact details and we will contact you as soon as possible',
      'Thank you!'
    ],
    fi: [
      'Viihdyitkö?',
      'Mukava kuulla! Onnistuimmeko jossain erityisesti?',
      'Harmi, mikä meni pieleen?',
      'Jätä yhteystietosi, jos haluat että otamme yhteyttä',
      'Kiitos palautteestasi!'
    ]
  },
  {
    en: [
      'Are you satisfied?',
      'Great to hear! Please, tell us more..',
      'Sad to hear. Please, tell us more..',
      'Add your contact info and we\'ll contact you',
      'Thank you!'
    ],
    fi: [
      'Oletko tyytyväinen?',
      'Mukava kuulla! Onnistuimmeko jossain erityisesti?',
      'Harmi, mitä voisimme tehdä toisin?',
      'Jätä yhteystietosi, jos haluat että otamme yhteyttä',
      'Kiitos palautteestasi!'
    ]
  }
];

export const PICTURES = [
  'https://taplause-app.s3.eu-central-1.amazonaws.com/wizard/default-images/taplause-default-images-1.jpg',
  'https://taplause-app.s3.eu-central-1.amazonaws.com/wizard/default-images/taplause-default-images-2.jpg',
  'https://taplause-app.s3.eu-central-1.amazonaws.com/wizard/default-images/taplause-default-images-3.jpg',
  'https://taplause-app.s3.eu-central-1.amazonaws.com/wizard/default-images/taplause-default-images-4.jpg'
];
/*
export const IS_VALID_TARGET = function(target) {
  return _.isObject(target) &&
    _.isString(target._id) &&
    _.isString(target.name) &&
    _.isObject(target.question) &&
    _.isString(target.picture) &&
    _.isObject(target.customizations) &&
    _.isArray(target.customizations.question) &&
    _.isArray(target.customizations.picture) &&
    target.entityType === 'target';
};
*/
export const IS_VALID_TARGET = function(target) {
  return (typeof target === 'object') &&
  (typeof target._id === 'string') &&
  (typeof target.name === 'string') &&
  (typeof target.question === 'object') &&
  (typeof target.picture === 'string') &&
  (typeof target.customizations === 'object') &&
  (target.customizations.question.constructor === Array) &&
  (target.customizations.picture.constructor === Array) &&
  target.entityType === 'target';
};
